<template>
  <div>
    <h1>pong</h1>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'ping', // Change to a multi-word name
};
</script>
