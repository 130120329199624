<template>
  <div>
    <h1>Access Denied</h1>
    <p>You do not have permission to view this page.</p>
  </div>
</template>

<script>
export default {
  name: 'UnauthorizedAccess', // Change to a multi-word name
};
</script>
