import { createRouter, createWebHistory } from 'vue-router';
import { getUserRoles, isAuthenticated, login } from '../services/auth';

import APIDocumentation from '../components/ApiDocumentation.vue';
import Unauthorized from '../components/UnauthorizedAccess.vue';
import Ping from '../components/ping.vue';

const routes = [
  {
    path: '/ping',
    name: 'ping',
    component: Ping,
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
  },
  {
    path: '/:context',
    name: 'PublicDocumentation',
    component: APIDocumentation,
  },
  {
    path: '/:context/internal',
    name: 'InternalDocumentation',
    component: APIDocumentation,
    meta: { requiresRole: 'internal-documents-read' },
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log('Navigating to:', to.path);

  const requiredRole = to.meta.requiresRole;

  if (requiredRole) {
    if (!isAuthenticated()) {
      // Redirect to login and save the intended path as a query parameter
      login({ redirectUri: `${window.location.origin}${to.fullPath}` });
    } else {
      const { realmRoles, clientRoles } = getUserRoles();
      const userHasRole = realmRoles.includes(requiredRole) || clientRoles.includes(requiredRole);

      if (userHasRole) {
        next(); // Proceed to the route if the user has the required role
      } else {
        next({ path: '/unauthorized', replace: true });
      }
    }
  } else {
    next(); // Proceed normally if no specific role is required
  }
});

export default router;
