import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
  url: 'https://login.dirs21.de',
  realm: 'no-register',
  clientId: 'tocc_api_documentation',
});

export const initializeKeycloak = (onAuthenticatedCallback) => {
  keycloak
    .init({
      onLoad: 'login-required', // Options: 'check-sso', 'login-required'
      pkceMethod: 'S256', // optional, for better security
    })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        console.warn('Not authenticated');
      }
    })
    .catch((error) => console.error('Failed to initialize Keycloak', error));
};


export const getUserRoles = () => {
  if (keycloak.tokenParsed) {
    // Fetch realm roles
    const realmRoles = keycloak.tokenParsed.realm_access
      ? keycloak.tokenParsed.realm_access.roles
      : [];
      
    // Fetch client roles (replace 'your-client-id' with the Keycloak client ID)
    const clientRoles = keycloak.tokenParsed.resource_access?.['tocc_api_documentation']?.roles || [];

    return { realmRoles, clientRoles };
  }
  return { realmRoles: [], clientRoles: [] };
};

export const login = keycloak.login;
export const logout = keycloak.logout;
export const getToken = () => keycloak.token;
export const isAuthenticated = () => keycloak.authenticated;
export const updateToken = (successCallback) =>
  keycloak.updateToken(5).then(successCallback).catch(login);

export default keycloak;




