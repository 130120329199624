<template>
  <div>
    <redoc :spec-url="url"></redoc>
  </div>
</template>

<script>

export default {
  mounted() {
    // Load the ReDoc standalone script dynamically from CDN
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/npm/redoc@latest/bundles/redoc.standalone.js";
    script.async = true;
    document.body.appendChild(script);
  },
  computed: {
    url() {
      return "https://api-" + this.context + ".tocc.dirs21.de/swagger/" + this.context2 + "/swagger.json";
    },
    context2() {
      return this.isInternal ? this.context + "%20internal" : this.context;
    },
    isInternal(){
      console.log(this.$route.path.split('/')[2] == "internal")
      return this.$route.path.split('/')[2] == "internal"; // Access the first segment
    },
    context() {
      // Split the path by '/' and return the first segment after the initial '/'
      return this.$route.path.split('/')[1]; // Access the first segment
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
