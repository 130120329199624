import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { initializeKeycloak } from './services/auth';


initializeKeycloak(() => {
  const app = createApp(App);
  app.use(router);
  app.mount('#app');
});
